import { Config } from "@/models/AppStateProvider";

export const randomString = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

const makeRSVPLink = ({
  url,
  config,
  myAccount,
}: {
  url: string;
  config: Config;
  myAccount: string;
}) => {
  const { pathname } = new URL(url);
  const domain = config.baseDomain.replace("api", "rsvp");
  const inviteUrl = `${domain}${pathname}?referringAccount=${myAccount}`;

  return inviteUrl;
};

export const isValidLink = (url: string) => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    url,
  );
};

export const isValidEncodedBase64 = (src: string) => {
  return /^[0-9a-zA-Z+\/]+[=]{0,3}$/.test(src);
};

export const truncate = (string: string, number?: number, dots?: string) =>
  string && string?.length && string?.length > number
    ? `${string.substring(0, number)}${dots}`
    : string;

export const convertCamelCaseToCapitalize = (string: string): string => {
  const result = string?.replace(/([A-Z])/g, " $1");
  return `${result?.charAt(0).toUpperCase()}${result?.slice(1)}`?.trim()?.replace(/ {2}/g, " ");
};

export const convertSnakeCaseToNoCaseCapitalize = (string: string): string =>
  string
    ?.split("_")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(" ")
    ?.trim()
    ?.replace(/ {2}/g, " ");
