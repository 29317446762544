import ButtonDropdownMenu from "@/components/ButtonDropdownMenu";
import AlphaSort from "@/components/Icons/AlphaSort";
import RecentSort from "@/components/Icons/RecentSort";
import StatusLight from "@/components/StatusLight";
import { FullInput } from "@/components/Utils";
import ChannelEmptyState from "@/components/WorkspaceChannelList/ChannelEmptyState";
import ChannelTypes from "@/components/WorkspaceChannelList/ChannelTypes";
import WorkspaceChannelLinkItem from "@/components/WorkspaceChannelList/WorkspaceChannelLinkItem";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import Locator from "@/locator";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { UxContext } from "@/models/UxStateProvider";
import {
  ChannelList,
  ChannelOrderType,
  ChannelTypeType,
  fetchChannelList,
  hasAliasChannelsInWorkspace,
  multipleWorkspaceAliasInsideFeed,
} from "@/models/commonQueries";
import { grayColor, greenColor, handleFaviconChange, redColor, yellowColor } from "@/utils";
import CancelRounded from "@mui/icons-material/CancelRounded";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { Box } from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useContext, useEffect, useMemo, useState } from "react";
import { VList } from "virtua";

export default function WorkspaceChannelListContainer({ notificationsBanner }: { notificationsBanner: boolean }) {
  const { userReadOnlyMode } = useContext(UxContext);
  const { isWorkspaceLimitedMember, currentWorkspaceId, myCurrentWorkspaceMembership } = useContext(WorkspaceContext);
  const { currentFeedId } = useContext(CurrentFeedContext);
  const { workspaceMembershipId } = useContext(LiveQueryContext);
  const limitedMember = isWorkspaceLimitedMember();
  const feedId = currentFeedId;
  const { myAccount } = useContext(MyAccountContext);

  const [channelOrder, saveChannelOrder] = useLocalStorage(
    `${currentWorkspaceId}-channel-sort-order`,
    "driver-activity" as ChannelOrderType,
  );

  const [channelFilterType, saveChannelFilterType] = useLocalStorage(`${currentWorkspaceId}-channel-filter-type`, null);

  const [channelType, saveChannelType] = useLocalStorage(
    `${currentWorkspaceId}-channel-sort-type`,
    "my-channels" as ChannelTypeType,
  );

  const [searchValue, setSearchValue] = useState<string>("");

  const tempOverRide = searchValue?.length > 0 && !limitedMember ? "all-channels" : null;

  const dynamicChannelType = tempOverRide !== null ? tempOverRide : (channelType as ChannelTypeType);

  const { rows: channelList } = useDrizzleSelect(
    fetchChannelList({
      channelOrder,
      channelType: dynamicChannelType,
      workspaceId: currentWorkspaceId,
      myCurrentWorkspaceMembership,
      searchValue,
      myAccount,
      channelFilter: channelFilterType,
    }),
  ) as { rows: ChannelList[] };

  const { rows: multipleAliasChannels } = useDrizzleSelect(multipleWorkspaceAliasInsideFeed(currentWorkspaceId));

  const { rows: workspaceAliasOwners } = useDrizzleSelect(hasAliasChannelsInWorkspace(currentWorkspaceId));
  const hasWorkspaceAliasOwners = workspaceAliasOwners?.length > 0;

  const channels = useMemo(
    () =>
      channelList?.map((channel) => ({
        ...channel,
        hasMultipleAlias: !!multipleAliasChannels.find((item) => item.feedId === channel.id),
      })),
    [channelList, multipleAliasChannels],
  );

  const unreadJoined = channels?.filter((channel) => channel.unread !== null && channel.subscribed)?.map((i) => i.id);

  const unreadCount = unreadJoined?.length;

  useEffect(() => {
    if (unreadJoined?.length > 0) {
      handleFaviconChange(unreadJoined);
    } else {
      handleFaviconChange([]);
    }
  }, [unreadJoined]);

  const [searchInputFullWidth, setSearchInputFullWidth] = useState<boolean>(false);

  const handleInputFocus = (value: boolean) => {
    if (value === true && !searchInputFullWidth) {
      setSearchInputFullWidth(() => true);
    } else if (value === false && searchValue.length === 0) {
      setSearchInputFullWidth(() => false);
    }
  };

  return (
    <Box
      className={`
        workspace-channel-list-container
        ${notificationsBanner ? "has-banner" : ""}
        ${userReadOnlyMode ? "read-only-mode" : ""}
      `}
    >
      <Box sx={{ display: "flex", p: 2 }} className="space-x-1">
        <Box sx={{ flexGrow: "1", position: "relative" }}>
          <FullInput
            onFocus={() => handleInputFocus(true)}
            onBlur={() => handleInputFocus(false)}
            value={searchValue}
            inputProps={{
              placeholder: "Search",
            }}
            formControlProps={{
              sx: {
                "& .MuiInputBase-input": {
                  paddingLeft: "1rem !important",
                },
              },
            }}
            callback={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          <Box className="channel-search-clear-button">
            {searchValue?.length ? (
              <button
                type="button"
                aria-label={Locator.workspaceNav.channels.find.clear}
                onClick={() => {
                  setSearchValue("");
                  setSearchInputFullWidth(() => false);
                }}
              >
                <CancelRounded />
              </button>
            ) : (
              <SearchIcon sx={{ color: searchValue?.length > 0 ? "#fff" : "#ccc" }} />
            )}
          </Box>
        </Box>
        <Box className="flex space-x-1">
          {hasWorkspaceAliasOwners && (
            <Box className="channel-options-button">
              <ButtonDropdownMenu
                indicateActive={true}
                activeValue={channelFilterType}
                changeValue={(value: string) => saveChannelFilterType(() => value)}
                values={[
                  {
                    text: "No Filter",
                    value: null,
                    icon: <FilterAltOffIcon sx={{ fontSize: "14px" }} />,
                    active: true,
                  },
                  {
                    text: "Available",
                    value: "Driving",
                    icon: <StatusLight color={greenColor} size={12} />,
                    active: false,
                  },
                  {
                    text: "Unknown",
                    value: "Unknown",
                    icon: <StatusLight color={yellowColor} size={12} />,
                    active: false,
                  },
                  {
                    text: "Not Available",
                    value: "Not Driving",
                    icon: <StatusLight color={redColor} size={12} />,
                    active: false,
                  },
                  {
                    text: "Offline",
                    value: "Offline",
                    icon: <StatusLight color={grayColor} size={12} />,
                    active: false,
                  },
                ]}
                title={Locator.workspaceNav.channels.dropdowns.filter.title}
                icon={<FilterListIcon />}
              />
            </Box>
          )}

          <Box className="channel-options-button">
            <ButtonDropdownMenu
              activeValue={channelOrder}
              changeValue={(value: ChannelOrderType) => saveChannelOrder(() => value)}
              values={[
                {
                  text: "Alphabetically",
                  value: "alpha-asc",
                  icon: <AlphaSort />,
                  active: true,
                },
                {
                  text: "By most recent",
                  value: "driver-activity",
                  icon: <RecentSort />,
                  active: false,
                },
              ]}
              title={Locator.workspaceNav.channels.dropdowns.sort.title}
              icon={<SwapVertIcon />}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ mb: 2, px: 1 }}>
        <ChannelTypes
          tempOverRide={tempOverRide}
          limitedMember={limitedMember}
          unreadCount={unreadCount}
          changeChannelType={(channelType: ChannelTypeType) => {
            saveChannelType(() => channelType);
            if (channelType !== "all-channels") {
              setSearchValue("");
            }
          }}
          activeChannelType={channelType}
        />
      </Box>

      <VList
        className="workspace-channel-list-items"
        aria-label={Locator.workspaceNav.channels.list.container}
        itemSize={40}
        shift={false}
        overscan={0}
        count={channels?.length}
        style={{
          height: notificationsBanner ? "calc(100vh - 435px)" : "calc(100vh - 345px)",
        }}
      >
        {channels?.length > 0 ? (
          <>
            {channels.map((channel) => (
              <WorkspaceChannelLinkItem
                limitedMember={limitedMember}
                readOnlyMode={userReadOnlyMode}
                key={channel.id}
                active={channel.id === feedId}
                channel={channel}
                workspaceMembershipId={workspaceMembershipId}
              />
            ))}
          </>
        ) : (
          <ChannelEmptyState
            activeFilter={channelFilterType}
            limitedMember={limitedMember}
            hasSearch={searchValue?.length > 0}
            channelType={channelType}
            changeChannelType={(channelType: ChannelTypeType) => {
              saveChannelType(channelType);
            }}
            clearFilters={() => {
              saveChannelFilterType(() => null);
              setSearchValue(() => "");
            }}
          />
        )}
      </VList>
    </Box>
  );
}
